<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { company_categories } from "@/config/api/company_categories";

/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      companyID: "",
      companyInfo:undefined,
      title: "Company Details",
      items: [
        {
          text: "View",
        },
        {
          text: "Company Details",
          active: true,
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.companyID = this.$route.query.id;
      this.loadInfo();
    }
  },
  methods: {
    loadInfo() {
      if (this.companyID) {
         const api = company_categories.get_sub;
        // api.url += this.companyID;
        this.generateAPI(api).then((res) => {
          this.companyInfo = res.data.categories.filter((item) => item._id === this.companyID);
          console.log(this.companyInfo);
        });
      }
    },
    
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="companyInfo !== undefined" class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5">
                <div class="product-detail" >
                  <div class="row">
                  
                    <div class="col-md-11 col-9">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="product-1"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              id="expandedImg"
                              :src="companyInfo.trade_license"
                              alt
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              
              <div class="col-xl-7">
                <div class="mt-4 mt-xl-3">
                  <a v-if="companyInfo.company_experties" href="#" class="text-primary">{{companyInfo.company_experties.name}}</a>
                  <h5 class="mt-1 mb-3">{{companyInfo.display_name}}</h5>
                  <h5 class="mt-1 mb-3">{{companyInfo.legal_name}}</h5>

                  <div class="d-inline-flex">
                    <div class="text-muted mr-3">
                       

                      <span v-if="companyInfo.rating" class="mdi mdi-star text-warning" :v-for="index in companyInfo.rating" :key='index'></span>
                      
                      <!-- <span class="mdi mdi-star"  :v-for="index in [1,2,3,4]" :key="index+10">
                          
                      </span> -->
                    </div>
                    <div class="text-muted"> {{companyInfo.views}}</div>
                  </div>

                  
                  <!-- <p class="mt-3">
                    To achieve this, it would be necessary to have uniform
                    pronunciation
                  </p> -->
                  <hr class="my-4" />

                  <div class="row">
                    <!-- <div class="col-md-6">
                      <div>
                        

                        <h5 class="font-size-14">Specification :</h5>
                        <ul class="list-unstyled product-desc-list">
                          <li v-for="(key,value) in companyInfo.custom_specifications" :key="key">
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>
                         {{value}}
                        :
                        {{key}} 
                          </li>
                          
                        </ul>
                      </div>
                    </div> -->

                    <!-- <div>
                      <h5 class="font-size-14">Specifications :</h5>
                      <ul class="list-unstyled product-desc-list">
                        <li  >
                          <i
                            class="
                              mdi mdi-office-building
                              text-primary
                              mr-1
                              align-middle
                              font-size-16
                            "></i>
                          Area : {{companyInfo.project_area}} sqft.
                        </li>
                        <li  >
                          <i
                            class="
                              mdi mdi-cash-usd-outline
                              text-primary
                              mr-1
                              align-middle
                              font-size-16
                            "></i>
                          Cost : {{companyInfo.project_cost}} 
                        </li>
                        <li> <i class="mdi mdi-timer text-primary mr-1 align-middle font-size-16"></i>Starting Date : {{companyInfo.start_date}} </li>
                          <li> <i class="mdi mdi-update text-primary mr-1 align-middle font-size-16"></i>Ending Date : {{companyInfo.end_date}} </li>
                        
                      </ul>
                    </div> -->
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="product-color mt-3">
                        <h5 class="font-size-14">Basic Info :</h5>
                         <ul class="list-unstyled product-desc-list">
                             <li >Status: {{companyInfo.deletedAt?'Inactive':'Active' }}  </li>
                             <li >Verified: {{companyInfo.is_verified?'Yes':'No' }}  </li>
                             <li >Featured: {{companyInfo.featured?'Yes':'No' }}  </li>
                         </ul>
                      </div>
                    </div>

                     
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <div class="mt-4">
              <h5 class="font-size-14 mb-3">More Info:</h5>
              <div class="product-desc">
                <b-tabs
                  class="nav-tabs-custom"
                  content-class="border border-top-0 p-4"
                >
               
                  <b-tab title="Company Members">
                     <div class="col-md-6">
                      <div class="product-color mt-3">
                      
                        <a href="#"  v-for="member in companyInfo.company_members" :key="member._id">
                          <div class="product-color-item">
                            <img
                              src="@/assets/mawj/Logo.png"
                              alt
                              class="avatar-md"
                            />
                          </div>
                          <p>{{member.name}}</p>
                        </a>
                      </div>
                    </div>
                    <!-- <div class="col-md-6" v-if="companyInfo.team_members.length==0">
                      No Members
                    </div> -->

                  </b-tab>
                </b-tabs>
              </div>
            </div>

         
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->

    <!-- end row -->
  </Layout>
</template>
